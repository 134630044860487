import * as React from 'react';
import { Component } from 'react';
import InstagramDark from '../../images/instagram-dark.png';
import FacebookDark from '../../images/facebook-dark.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';

class Header extends Component {
   constructor() {
      super();
      this.state = {
         expanded: true,
         display: 'hide',
         isHovered: false,
         isClicked: false,
         hoverSection: ''
      };
   }

   setIsHovered = (isHovered, section) => {
      this.setState({
         isHovered: isHovered,
         hoverSection: isHovered ? section : ''
      });
   };

   removeUnderlineOnHover = section => {
      if (this.state.isHovered && this.state.hoverSection !== section) {
         return 'border-transparent';
      } else return '';
   };

   render() {
      return (
         <>
            <header className={`${this.props.mobileMenuOpen ? 'mobile-menu-open' : ''}`}>
               <div
                  className={`dropdown-background ${this.state.isHovered ? 'show' : 'hide'}`}
               />
               <Container className='px-0 pt-3 pt-md-5'>
                  <Row>
                     <Col xs={11} lg={10} className='m-auto pb-4'>
                        <Container className='p-0'>
                           <Row>
                              <Col xs={11} lg={12} className='logo-cont px-0 m-auto'>
                                 <a className='navbar-brand' href='/'>
                                    <img
                                       src={'/gilead-hiv.svg'}
                                       alt='Image of GILEAD HIV Logo'
                                    />
                                 </a>
                                 <button
                                    onClick={this.props.toggleMobileMenu}
                                    className='navbar-toggler'
                                    type='button'
                                    data-toggle='collapse'
                                    data-target='#navbarSupportedContent'
                                    aria-controls='navbarSupportedContent'
                                    aria-expanded='false'
                                    aria-label='Toggle navigation'
                                 >
                                    <i className='fa fa-bars'></i>
                                 </button>
                              </Col>
                              <Col
                                 lg={9}
                                 className='d-none d-lg-block px-0 home-navigation'
                              >
                                 <Dropdown
                                    onMouseEnter={() =>
                                       this.setIsHovered(true, 'about')
                                    }
                                    onMouseLeave={() => this.setIsHovered(false)}
                                    onToggle={this.dropdownToggled}
                                    className={`${this.state.hoverSection === 'about'
                                          ? ''
                                          : 'transparent'
                                       }`}
                                    show={this.state.isHovered}
                                 >
                                    <Dropdown.Toggle
                                       id='dropdown-basic'
                                       className={`
                            ${this.props.section === 'about' ? 'active' : ''}
                            ${this.removeUnderlineOnHover('about')}
                          `}
                                    >
                                       ABOUT US
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/about-us'
                                          className='gtm-flag-header-nav-link test'
                                          data-analytics-parent-category='About Us'
                                       >
                                          <span>We Are All in This Together</span>
                                       </Dropdown.Item>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/community-involvement/community-commitments/'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='About Us'
                                       >
                                          <span>Community Commitments</span>
                                       </Dropdown.Item>
                                    </Dropdown.Menu>
                                 </Dropdown>

                                 <Dropdown
                                    onMouseEnter={() =>
                                       this.setIsHovered(true, 'landscape')
                                    }
                                    onMouseLeave={() => this.setIsHovered(false)}
                                    onToggle={this.dropdownToggled}
                                    className={`${this.state.hoverSection === 'landscape'
                                          ? ''
                                          : 'transparent'
                                       }`}
                                    show={this.state.isHovered}
                                 >
                                    <Dropdown.Toggle
                                       id='dropdown-basic-0'
                                       className={`
                            ${this.props.section === 'hiv-landscape' ? 'active' : ''}
                            ${this.removeUnderlineOnHover('landscape')}
                          `}
                                    >
                                       HIV LANDSCAPE
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/landscape/history-of-hiv'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='HIV Landscape'
                                       >
                                          <span>History of the HIV Epidemic</span>
                                       </Dropdown.Item>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/landscape/state-of-epidemic'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='HIV Landscape'
                                       >
                                          <span>State of the HIV Epidemic</span>
                                       </Dropdown.Item>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/landscape/ehe'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='HIV Landscape'
                                       >
                                          <span>
                                             Plans for Ending the HIV Epidemic
                                          </span>
                                       </Dropdown.Item>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/landscape/hiv-care-continuum'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='HIV Landscape'
                                       >
                                          <span>HIV Care Continuum</span>
                                       </Dropdown.Item>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/landscape/hiv-testing'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='HIV Landscape'
                                       >
                                          <span>HIV Testing</span>
                                       </Dropdown.Item>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/landscape/hiv-rapid-start'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='HIV Landscape'
                                       >
                                          <span>
                                             Rapid Start Initiation
                                          </span>
                                       </Dropdown.Item>
                                    </Dropdown.Menu>
                                 </Dropdown>

                                 <Dropdown
                                    onMouseEnter={() =>
                                       this.setIsHovered(true, 'community')
                                    }
                                    onMouseLeave={() => this.setIsHovered(false)}
                                    onToggle={this.dropdownToggled}
                                    className={`${this.state.hoverSection === 'community'
                                          ? ''
                                          : 'transparent'
                                       }`}
                                    show={this.state.isHovered}
                                 >
                                    <Dropdown.Toggle
                                       id='dropdown-basic-1'
                                       className={`
                            ${this.props.section === 'community-involvement' ? 'active' : ''}
                            ${this.removeUnderlineOnHover('community')}
                        `}
                                    >
                                       COMMUNITY INVOLVEMENT
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/community-involvement/community-commitments/'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='Community Involvement'
                                       >
                                          <span>Community Commitments</span>
                                       </Dropdown.Item>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/championsofchange/'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='Community Involvement'
                                       >
                                          <span>Champions of Change: Black Women Leaders</span>
                                       </Dropdown.Item>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/community-involvement/blind-angels/'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='Community Involvement'
                                       >
                                          <span>Blind Angels</span>
                                       </Dropdown.Item>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/community-involvement/voices-of-strength/'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='Community Involvement'
                                       >
                                          <span>Voices of Strength</span>
                                       </Dropdown.Item>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/community-involvement/social-toolkit/'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='Community Involvement'
                                       >
                                          <span>Social Media Toolkit</span>
                                       </Dropdown.Item>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/community-involvement/events/'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='Community Involvement'
                                       >
                                          <span>Events</span>
                                       </Dropdown.Item>
                                    </Dropdown.Menu>
                                 </Dropdown>

                                 <Dropdown
                                    onMouseEnter={() =>
                                       this.setIsHovered(true, 'deeper')
                                    }
                                    onMouseLeave={() => this.setIsHovered(false)}
                                    onToggle={this.dropdownToggled}
                                    className={`${this.state.hoverSection === 'deeper'
                                          ? ''
                                          : 'transparent'
                                       }`}
                                    show={this.state.isHovered}
                                 >
                                    <Dropdown.Toggle
                                       id='dropdown-basic-1'
                                       className={`
                            ${this.props.section === 'deeper-dive' ? 'active' : ''}
                            ${this.removeUnderlineOnHover('deeper')}
                        `}
                                    >
                                       DEEPER DIVE
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                       <Dropdown.Item
                                          target={this.props.section === 'orphan' ? "_blank" : undefined}
                                          rel={this.props.section === 'orphan' ? "noopener noreferrer" : undefined}
                                          href='/deeper-dive/content-index/'
                                          className='gtm-flag-header-nav-link'
                                          data-analytics-parent-category='Deeper Dive'
                                       >
                                          <span>Featured Articles</span>
                                       </Dropdown.Item>
                                    </Dropdown.Menu>
                                 </Dropdown>
                              </Col>
                              <Col lg={3} className='text-right'>
                                 <div className='social '>
                                    <a
                                       href='https://www.instagram.com/gileadhivus/'
                                       target='_blank'
                                    >
                                       <img
                                          src={InstagramDark}
                                          height='49'
                                          width='49'
                                          alt='instagram icon'
                                       />
                                    </a>
                                    <a
                                       href='https://www.facebook.com/GileadHIVUS/'
                                       target='_blank'
                                    >
                                       <img
                                          src={FacebookDark}
                                          height='49 '
                                          width='49 '
                                          alt='facebook icon '
                                       />
                                    </a>
                                 </div>
                              </Col>
                           </Row>
                        </Container>
                     </Col>
                  </Row>
               </Container>
            </header>
         </>
      );
   }
}

export default Header;
