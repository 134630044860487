import * as React from "react";
import { Component } from 'react';
import Post1 from '../../images/post_1.png';
import Post2 from '../../images/post_2.png';
import Post3 from '../../images/post_3.png';
import Post4 from '../../images/post_4.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class JoinUsOnSocial extends Component {
  render(){
    return (
      <>
        <Container fluid
          className={`
            ${this.props.show ? 'd-block' : 'd-none'} 
            join-us turquoise
          `}>
          <Container className="px-0">
            <Row>
              <Col xs={{span: 11, offset: 1}} md={{span: 10}} lg={10} className="content mx-auto mt-4 pt-4">
                <h2 className="title walls-io-header">Join us on social</h2>
                <iframe 
                  allowFullScreen id="wallsio-iframe" 
                  src="https://my.walls.io/i4sp7?nobackground=1&amp;theme=fluid&amp;colorscheme=light&amp;show_header=0" 
                  style={{border:"0",height:"550px",width:"100%"}}
                  loading="lazy" 
                  title="My social wall">
                </iframe>
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    )
  }
}

export default JoinUsOnSocial;