import * as React from "react";
import { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "./header.js";
import MenuMobile from "./menu-mobile.js";
import Footer from "./footer.js";
import JoinUsOnSocial from "./join-us-on-social.js";
import "@fortawesome/fontawesome-free/css/all.css";
import "../../sass/app.scss";
import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import SEO from "./SEO.js";
import SocialShareImg from "../../images/social-share.png";

const InterstitialContent = (props) => {
  if (props.isGeneric) {
    return (
      <p>
        By following this link, you are leaving GileadHIV.com. Gilead provides
        these links as a convenience. But these sites are not controlled by
        Gilead. Gilead is not responsible for their content or your use of them.
        For medical advice, please contact your healthcare provider.
      </p>
    );
  } else {
    return (
      <p>
        By following this link, you are leaving GileadHIV.com. AIDSVu is
        presented by the Rollins School of Public Health at Emory University in
        partnership with Gilead. Gilead provides this link as a convenience. For
        medical advice, please contact your healthcare provider.
      </p>
    );
  }
};

class Layout extends Component {
  constructor() {
    super();
    this.state = {
      mobileMenuOpen: false,
      showInterstitial: false,
      interStitialLink: undefined,
      interstitialGeneric: true,
    };
  }
  toggleMobileMenu = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
  };
  closeInterstitial = () => {
    this.setState({
      showInterstitial: false,
    });
  };

  listenForInterstitialEvents = () => {
    $("a").on("click", (e) => {
      let href = e.currentTarget.href;
      let noInterstitial = $(e.currentTarget).hasClass("no-interstitial");
      if (!href.includes(window.location.hostname) && !noInterstitial) {
        e.preventDefault();
        this.setState({
          showInterstitial: true,
          interStitialLink: href,
          interstitialGeneric:
            !e.currentTarget.href.includes("https://aidsvu.org"),
          analyticsEventCategory:
            e.currentTarget.dataset.analyticsEventCategory,
          analyticsEventLabel: e.currentTarget.dataset.analyticsEventLabel,
        });
      }
    });
  };
  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.showInterstitial && !this.state.showInterstitial) {
      return window.scrollY;
    }
    return null;
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (!prevState.mobileMenuOpen && this.state.mobileMenuOpen) {
      window.scrollTo(0, 0);
    }
    document.body.style.background = this.state.mobileMenuOpen
      ? "#f7f7ee"
      : "#FFF";
    if (snapshot) {
      window.scrollTo(0, snapshot - 1);
    }
  };
  componentDidMount = () => {
    this.listenForInterstitialEvents();
  };
  componentWillUnmount = () => {
    $("a").off("click");
  };
  updateWindowLocation = () => {
    window.open(this.state.interStitialLink, "_blank");
    this.setState({
      showInterstitial: false,
      interStitialLink: undefined,
    });
  };

  render() {
    const show = !this.state.mobileMenuOpen;
    const newChild = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, { show });
    });
    return (
      <>
        <Modal
          centered
          show={this.state.showInterstitial}
          onHide={this.closeInterstitial}
          className="interstitial"
        >
          <Modal.Body>
            <div className="content">
              <div className="title">You are about to leave GileadHIV.com</div>
              <InterstitialContent isGeneric={this.state.interstitialGeneric} />
              <button
                onClick={this.closeInterstitial}
                 // data-analytics-event-category={this.state.analyticsEventCategory} data-analytics-event-label={`${this.state.analyticsEventLabel} Go Back`}
                 className="btn gray dark"
              >
                Go Back
              </button>
              <button
                onClick={this.updateWindowLocation}
                className="btn red"
              >
                Continue
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Helmet title={SEO[this.props.page].title}>
        <meta name="description" content={SEO[this.props.page].description} />
        <meta property="og:image" content={SocialShareImg} />
        <link rel="stylesheet" href="https://use.typekit.net/qdo5erq.css" />
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500&display=swap" rel="stylesheet"/>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500&display=swap');
        </style>
        </Helmet>
        <Header
          section={this.props.section}
          mobileMenuOpen={this.state.mobileMenuOpen}
          toggleMobileMenu={this.toggleMobileMenu}
        />
        <MenuMobile
          section={this.props.section}
          show={this.state.mobileMenuOpen}
        />
        {newChild}
        <JoinUsOnSocial
          show={
            !this.state.mobileMenuOpen &&
            this.props.section !== "site-map" &&
            this.props.section !== "404"
          }
        />
        <Footer
          page={this.props.page}
          mobileMenuOpen={this.state.mobileMenuOpen}
          isSiteMap404={
            this.props.section === "site-map" || this.props.section === "404"
          }
        />
      </>
    );
  }
}

export default Layout;
