import * as React from 'react';
import { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { getCurrentPage } from '../utils';

class MenuMobile extends Component {
   constructor() {
      super();
      this.state = {
         showAllSections: true,
         section: 'landscape'
      };
   }
   // componentDidMount() {
   //   let currentRoute = getCurrentPage(); // get the current route
   //   if (currentRoute) { // current route is not empty string (aka home page)
   //     console.log(currentRoute);
   //     let currentLinkEl = document.querySelector(`a.${currentRoute}`);
   //     if (currentLinkEl) {
   //       currentLinkEl.classList.add('current-mobile-menu-link');
   //     }
   //   }
   // }

   sectionClick = section => {
      this.setState({
         showAllSections: !this.state.showAllSections,
         section: section
      });
   };
   render() {
      return (
         <Container
            className={`menu-mobile-cont 
             ${this.props.show ? 'd-block' : 'd-none'} `}
         >
            <Row>
               <Col xs={{ span: 10, offset: 1 }}>
                  <ul className='menu-mobile'>
                     <li
                        className={`
                  ${this.state.showAllSections ? 'd-block' : 'd-none'}
                  ${this.state.section === 'about' ? 'd-block' : ''}
                  `}
                     >
                        <span onClick={() => this.sectionClick('about', 'pages')}>
                           <i
                              className={`fa fa-chevron-left pl-0 ${this.state.showAllSections ? 'd-none' : 'd-inline'
                                 }`}
                           ></i>
                           About Us
                           <i
                              className={`fa fa-chevron-right ${this.state.showAllSections ? 'd-inline' : 'd-none'
                                 }`}
                           ></i>
                        </span>
                        <ul
                           className={`menu-pages ${this.state.showAllSections ? 'd-none' : 'd-block'
                              }`}
                        >
                           <li>
                              <a
                                 href='/about-us'
                                 className='about-us gtm-flag-header-nav-link'
                                 data-analytics-parent-category='About Us'
                              >
                                 We Are All in This Together
                              </a>
                           </li>
                           <li>
                              <a
                                 href='/community-involvement/community-commitments'
                                 className='community-commitments gtm-flag-header-nav-link'
                                 data-analytics-parent-category='About Us'
                              >
                                 Community Commitments
                              </a>
                           </li>
                        </ul>
                     </li>

                     <li
                        className={`
                ${this.state.showAllSections ? 'd-block' : 'd-none'}
                ${this.state.section === 'landscape' ? 'd-block' : ''}
              `}
                     >
                        <span onClick={() => this.sectionClick('landscape', 'pages')}>
                           <i
                              className={`fa fa-chevron-left pl-0 ${this.state.showAllSections ? 'd-none' : 'd-inline'
                                 }`}
                           ></i>
                           HIV Landscape
                           <i
                              className={`fa fa-chevron-right ${this.state.showAllSections ? 'd-inline' : 'd-none'
                                 }`}
                           ></i>
                        </span>
                        <ul
                           className={`menu-pages ${this.state.showAllSections ? 'd-none' : 'd-block'
                              }`}
                        >
                           <li>
                              <a
                                 href='/landscape/history-of-hiv'
                                 className='history-of-hiv gtm-flag-header-nav-link'
                                 data-analytics-parent-category='HIV Landscape'
                              >
                                 History of the HIV Epidemic
                              </a>
                           </li>
                           <li>
                              <a
                                 href='/landscape/state-of-epidemic'
                                 className='state-of-epidemic gtm-flag-header-nav-link'
                                 data-analytics-parent-category='HIV Landscape'
                              >
                                 State of the HIV Epidemic
                              </a>
                           </li>
                           <li>
                              <a
                                 href='/landscape/ehe'
                                 className='ehe gtm-flag-header-nav-link'
                                 data-analytics-parent-category='HIV Landscape'
                              >
                                 Plans for Ending the HIV Epidemic
                              </a>
                           </li>
                           <li>
                              <a
                                 href='/landscape/hiv-care-continuum'
                                 className='hiv-care-continuum gtm-flag-header-nav-link'
                                 data-analytics-parent-category='HIV Landscape'
                              >
                                 HIV Care Continuum
                              </a>
                           </li>
                           <li>
                              <a
                                 href='/landscape/hiv-testing'
                                 className='hiv-testing gtm-flag-header-nav-link'
                                 data-analytics-parent-category='HIV Landscape'
                              >
                                 HIV Testing
                              </a>
                           </li>
                           <li>
                              <a
                                 href='/landscape/hiv-rapid-start'
                                 className='hiv-rapid-start gtm-flag-header-nav-link'
                                 data-analytics-parent-category='HIV Landscape'
                              >
                                 Rapid Start Initiation
                              </a>
                           </li>
                        </ul>
                     </li>

                     <li
                        className={`
                  ${this.state.showAllSections ? 'd-block' : 'd-none'}
                  ${this.state.section === 'community' ? 'd-block' : ''}
                  `}
                     >
                        <span onClick={() => this.sectionClick('community', 'pages')}>
                           <i
                              className={`fa fa-chevron-left pl-0 ${this.state.showAllSections ? 'd-none' : 'd-inline'
                                 }`}
                           ></i>
                           Community Involvement
                           <i
                              className={`fa fa-chevron-right ${this.state.showAllSections ? 'd-inline' : 'd-none'
                                 }`}
                           ></i>
                        </span>
                        <ul
                           className={`menu-pages ${this.state.showAllSections ? 'd-none' : 'd-block'
                              }`}
                        >
                           <li>
                              <a
                                 href='/community-involvement/community-commitments'
                                 className='community-commitments gtm-flag-header-nav-link'
                                 data-analytics-parent-category='Community Involvement'
                              >
                                 Community Commitments
                              </a>
                           </li>
                           <li>
                              <a
                                 href='/championsofchange'
                                 className='community-commitments gtm-flag-header-nav-link'
                                 data-analytics-parent-category='Celebrating Black Women Leaders'
                              >
                                 Champions of Change:<br />Black&nbsp;Women&nbsp;Leaders
                              </a>
                           </li>
                           <li>
                              <a
                                 href='/community-involvement/blind-angels'
                                 className='blind-angels gtm-flag-header-nav-link'
                                 data-analytics-parent-category='Community Involvement'
                              >
                                 Blind Angels
                              </a>
                           </li>
                           <li>
                              <a
                                 href='/community-involvement/voices-of-strength'
                                 className='voices-of-strength gtm-flag-header-nav-link'
                                 data-analytics-parent-category='Community Involvement'
                              >
                                 Voices of Strength
                              </a>
                           </li>
                           <li>
                              <a
                                 href='/community-involvement/social-toolkit'
                                 className='events gtm-flag-header-nav-link'
                                 data-analytics-parent-category='Community Involvement'
                              >
                                 Social Media Toolkit
                              </a>
                           </li>
                           <li>
                              <a
                                 href='/community-involvement/events'
                                 className='events gtm-flag-header-nav-link'
                                 data-analytics-parent-category='Community Involvement'
                              >
                                 Events
                              </a>
                           </li>
                        </ul>
                     </li>

                     <li
                        className={`
                  ${this.state.showAllSections ? 'd-block' : 'd-none'}
                  ${this.state.section === 'deeper-dive' ? 'd-block' : ''}
                  `}
                     >
                        <span onClick={() => this.sectionClick('deeper-dive', 'pages')}>
                           <i
                              className={`fa fa-chevron-left pl-0 ${this.state.showAllSections ? 'd-none' : 'd-inline'
                                 }`}
                           ></i>
                           Deeper Dive
                           <i
                              className={`fa fa-chevron-right ${this.state.showAllSections ? 'd-inline' : 'd-none'
                                 }`}
                           ></i>
                        </span>
                        <ul
                           className={`menu-pages ${this.state.showAllSections ? 'd-none' : 'd-block'
                              }`}
                        >
                           <li>
                              <a
                                 href='/deeper-dive/content-index/'
                                 className='deeper-dive gtm-flag-header-nav-link'
                                 data-analytics-parent-category='Deeper Dive'
                              >
                                 Featured Articles
                              </a>
                           </li>
                        </ul>
                     </li>
                  </ul>
               </Col>
            </Row>
         </Container>
      );
   }
}

export default MenuMobile;
