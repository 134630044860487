const SEO = {
   home: {
      title: "Helping See the Age of HIV Come to an End | Gilead HIV",
      description:
         "Explore Gilead HIV to learn about the HIV epidemic. Find HIV events and resources.",
   },
   about: {
      title: "What We Live For® | Gilead HIV",
      description:
         "Learn how Gilead commits to helping end the HIV epidemic through scientific advancements, community activism, HIV education, corporate giving & more.",
   },
   history: {
      title: "History of the HIV Epidemic | Gilead HIV",
      description:
         "Explore the history of the HIV epidemic. Learn about scientific advancements, health disparities, HIV stigma, and advocacy & activism.",
   },
   state: {
      title: "State of the HIV Epidemic | Gilead HIV",
      description:
         "Explore the state of the HIV epidemic in the United States and the populations that are most affected by HIV. Find HIV statistics and data.",
   },
   "ending-hiv": {
      title: "Plans to Help End the HIV Epidemic | Gilead HIV",
      description:
         "Compare two public health policies to help end the HIV epidemic by 2030: Ending the HIV Epidemic (EHE) initiative and the HIV National Strategic Plan.",
   },
   "hiv-rapid-start": {
      title: "Rapid Start Initiation | Gilead HIV",
      description:
         "Learn about Rapid Start Initiation, and why the initiation of antiretroviral treatment (ART) is recommended by the DHHS, WHO and AETC",
   },
   "care-continuum": {
      title: "The HIV Care Continuum | Gilead HIV",
      description:
         "Explore the HIV care continuum: diagnosis, prevention, treatment, viral suppression. Learn about PrEP, a treatment strategy for people at risk of HIV.",
   },
   testing: {
      title: "HIV Testing | Gilead HIV",
      description:
         "Learn about HIV testing, including the HIV testing cost, barriers to testing, and CDC recommendations. Explore the types of HIV tests.",
   },
   "social-toolkit": {
      title: "Social Media Toolkit | Gilead HIV",
      description:
         "HIV awareness days help call attention to the impact of HIV. Find downloadable images and captions to help you build your own HIV awareness posts here.",
   },
   events: {
      title: "Events Supporting the HIV Community | Gilead HIV",
      description:
         "Explore Gilead programs working from the ground up with HIV leaders, advocates & communities. Find HIV resources like webinars, conferences & AIDS walks.",
   },
   "blind-angels": {
      title: "Blind Angels | Gilead HIV",
      description:
         "Check out the Gilead HIV docuseries Blind Angels, which follows activists as they work to bring HIV awareness, education, prevention, and care to their communities in the American South.",
   },
   zakia: {
      title: "Blind Angels – Zakia | Gilead HIV",
      description:
         "Meet Zakia McKensey, executive director and founder of Nationz Foundation, as she discusses how her organization's mobile health units help bring HIV care to underserved communities in the American South.",
   },
   jose: {
      title: "Blind Angels – José & Joaquín | Gilead HIV",
      description:
         "Meet HIV advocates José Romero and Joaquín Carcaño who are discussing their work to increase the use of PrEP medicines in the Hispanic/Latino community in the American South.",
   },
   tony: {
      title: "Blind Angels – Tony | Gilead HIV",
      description:
         "Meet Tony Christon-Walker, the former director of Prevention for AIDS Alabama, who helps Black men who have sex with men (MSM) overcome barriers to HIV care in the American South.",
   },
   dazon: {
      title: "Blind Angels – Dázon | Gilead HIV",
      description:
         "Meet Dázon Dixon Diallo, founder and president of SisterLove, Inc., an organization that helps to provide HIV prevention, education, and care for Black women in the American South.",
   },
   "marlene-mcneese": {
      title: "Deeper Dive – Marlene | Gilead HIV",
      description:
         "Marlene McNeese, PACHA co-chair and assistant director of Disease Prevention and Control for the Houston Health Department talks about the impact of the COVID-19 pandemic on HIV testing.",
   },
   "tiffany-west": {
      title: "Deeper Dive – Tiffany West | Gilead HIV",
      description:
         "Learn more about Gilead Sciences’ team working on initiatives such as the Historically Black Colleges and Universities (HBCU) Roadshow, including an interview with Tiffany West, Senior Director of Advancing Health and Black Equity at Gilead.",
   },
   kayla: {
      title: "Blind Angels – Kayla | Gilead HIV",
      description:
         "Meet Kayla Rena Gore, advocate and co-founder of My Sistah’s House , a grassroots organization that provides emergency housing and other services to the LGBTQIA+ community in Memphis, Tennessee.",
   },
   "champion-danielle": {
      title: "Danielle - Champion of Change",
      description: "Danielle - Champion of Change",
   },
   "champion-dafina": {
      title: "Dafina - Champion of Change",
      description: "Dafina - Champion of Change",
   },
   "champion-dazon": {
      title: "Dazon - Champion of Change",
      description: "Dazon - Champion of Change",
   },
   "champion-june": {
      title: "June - Champion of Change",
      description: "June - Champion of Change",
   },
   "champion-kayla": {
      title: "Kayla - Champion of Change",
      description: "Kayla - Champion of Change",
   },
   "champion-leisha": {
      title: "Leisha - Champion of Change",
      description: "Leisha - Champion of Change",
   },
   "champion-linda": {
      title: "Linda - Champion of Change",
      description: "Linda - Champion of Change",
   },
   "champion-marlene": {
      title: "Marlene - Champion of Change",
      description: "Marlene - Champion of Change",
   },
   "champion-raniyah": {
      title: "Raniyah - Champion of Change",
      description: "Raniyah - Champion of Change",
   },
   "champion-tori": {
      title: "Tori - Champion of Change",
      description: "Tori - Champion of Change",
   },
   "kayla-quimbley": {
      title: "Kayla Quimbley",
      description: "Kayla Quimbley",
   },
   "voices-of-strength": {
      title: "Voices of Strength | Gilead HIV",
      description:
         "Discover Gilead HIV “Voices of Strength” video series which celebrates personal stories of strength, perseverance, and triumph in the face of HIV stigma.",
   },
   "community-commitments": {
      title: "Community Commitments | Gilead HIV",
      description:
         "Organizations that are recipients of Gilead’s grant funding, working together to improve health equity and bring awareness to help end the HIV epidemic.",
   },
   "celebrating-black-women-leaders": {
      title: "Celebrating Black Women Leaders | Gilead HIV",
      description:
         "Organizations that are recipients of Gilead’s grant funding, working together to improve health equity and bring awareness to help end the HIV epidemic.",
   },
   "living-mosaic": {
      title: "The Living Mosaic | Gilead HIV",
      description:
         "Interact with The Living Mosaic: the Gilead HIV collection of inspirations from the community around helping end the HIV epidemic.",
   },
   "site-map": {
      title: "Site Map | Gilead HIV",
      description:
         "Access the site map for Gilead HIV to find the information you are seeking.",
   },
   "content-index": {
      title: "Deeper Dive - Featured Articles | Gilead HIV",
      description:
         "Find articles, resources, and social posts about what’s going on in the HIV community today and meet the people working to help end the HIV epidemic.",
   },
   "callen-lorde": {
      title: "Deeper Dive – Callen-Lorde | Gilead HIV",
      description:
         "Aruna Krishnakumar, Managing Director of Adolescent & Mobile Health at Callen-Lorde, discusses their Gilead-sponsored National HIV Testing Day event.",
   },
   "24th-ias-conference-2022": {
      title: "Deeper Dive - AIDS 2022 | Gilead HIV",
      description:
         "Learn how to connect with Gilead HIV at the 24th International AIDS Society (IAS) Conference, AIDS 2022. We are looking forward to reconnecting with you.",
   },
   "ehe-masterclass": {
      title: "Deeper Dive - EHE Masterclass | Gilead HIV",
      description:
         "Watch the EHE Masterclass—part of our Blueprints for Success Training Institute series. Presented by Dr Oni Blackstock and Greg Millet, moderated by Dr Patrick Sullivan",
   },
   "USCHA-conference-2022": {
      title: "Deeper Dive - USCHA Conference 2022 | Gilead HIV",
      description: "USCHA page",
   },

   404: {
      title: "404 Error | GILEAD HIV",
      description:
         "Sorry, we could not find the page you are looking for. Browse the GILEAD HIV site map for more.",
   },

   "prep-basics": {
      title: "History of the HIV Epidemic | Gilead HIV",
      description:
         "Explore the history of the HIV epidemic. Learn about scientific advancements, health disparities, HIV stigma, and advocacy & activism.",
   },
};

export default SEO;
